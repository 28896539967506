
<template>
  <div id="app">
    <Menu/>
    <router-view :key="$route.fullPath"/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '@/components/footer.vue'
import Menu from '@/components/menu.vue'
export default {
  components:{Footer, Menu}
}
</script>
<style>
#app {
  font-family: 'Roboto Slab', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
      color: #cccccc !important;
      font-weight: 400;
}

img{
  width: 100%;
}
img{
  width: 100%;
}
</style>
