<template>
  <div>
    <section class="sponRow" v-if="this.$route.name === 'home' || this.$route.name === 'about-us' || this.$route.name === 'our-services' || this.$route.name === 'case-studies'">
      <b-container>
        <h3 class="slideTitle">
          We've worked with
        </h3>
        <div>
        <VueSlickCarousel :arrows="true" :dots="false" :slidesToShow="5" :autoplay="true" :autoplaySpeed="2000">
          <div v-if="sponsors[0].acf.logo.length > 0"><img class="sponsorsLogo firstLogo" :src="sponsors[0].acf.logo"></div>
          <div v-if="sponsors[1].acf.logo.length > 0"><img class="sponsorsLogo 2" :src="sponsors[1].acf.logo"></div>
          <div v-if="sponsors[2].acf.logo.length > 0"><img class="sponsorsLogo 3" :src="sponsors[2].acf.logo"></div>
          <div v-if="sponsors[3].acf.logo.length > 0"><img class="sponsorsLogo 4" :src="sponsors[3].acf.logo"></div>
          <div v-if="sponsors[4].acf.logo.length > 0"><img class="sponsorsLogo 5" :src="sponsors[4].acf.logo"></div>
          <div v-if="sponsors[5].acf.logo.length > 0"><img class="sponsorsLogo" :src="sponsors[5].acf.logo"></div>
          <div v-if="sponsors[6].acf.logo.length > 0"><img class="sponsorsLogo" :src="sponsors[6].acf.logo"></div>
          <div v-if="sponsors[7].acf.logo.length > 0"><img class="sponsorsLogo" :src="sponsors[7].acf.logo"></div>
          <div v-if="sponsors[8].acf.logo.length > 0"><img class="sponsorsLogo" :src="sponsors[8].acf.logo"></div>
          <div v-if="sponsors[9].acf.logo.length > 0"><img class="sponsorsLogo" :src="sponsors[9].acf.logo"></div>
        </VueSlickCarousel>
        </div>
        <!-- <b-carousel
          v-model="slide"
          :interval="4000"
          background="#f2f2f2"
          img-width="1024"
          img-height="250"
        >
          <b-carousel-slide
            img-blank
            img-src="https://picsum.photos/1024/480/?image=54"
          >
            <b-row>
              <b-col cols="12" lg="3" md="12" sm="6" v-for="(spon, index) in sponsors.slice(0, 4)" :key="index">
                <b-img class="workedCompany" :src="spon.acf.logo"></b-img>
              </b-col>
            </b-row>
          </b-carousel-slide>
          <b-carousel-slide
            img-blank
            img-src="https://picsum.photos/1024/480/?image=54"
          >
            <b-row>
              <b-col cols="12" lg="3" md="12" sm="6" v-for="(spon, index) in sponsors.slice(4, 8)" :key="index">
                <b-img class="workedCompany" :src="spon.acf.logo"></b-img>
              </b-col>
            </b-row>
          </b-carousel-slide>
        </b-carousel> -->
        <b-button 
          :to="{
            name: 'case-studies',
            query: {
            id: 15,
            },
          }" class="c2a">View case studies
        </b-button>
      </b-container>
    </section>
    <section class="lightBlue">
      <b-container>
        <b-row>
          <b-col md='4'>
            <h3 class="footLBTitle">
              We're Safe
            </h3>
            <div class="para">
              <p>
                With all our engineers carrying CSCS cards and being members of
                SMAS we make Health and Safety a top priority
              </p>
            </div>

            <b-img
              class="gas"
              src="https://ocs-sport.ams3.digitaloceanspaces.com/gpplumbing/2020/03/SMAS-logo-Worksafe.jpg"
            ></b-img>

            <b-img
              class="gas"
              src="https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/gas-safe.png"
            ></b-img>
          </b-col>
          <b-col md='4'>
            <h3 class="footLBTitle">
              We're ready to chat
            </h3>
            <div class="para">
              <p>
                Unit 9A Ashdown Business Park
                <br />
                Michael Way
                <br />
                Maresfield
                <br />
                TN22 2DU
              </p>
              <p>Phone: <a href="tel:01732 865155">01732 865155</a></p>
              <p>
                Business Hours: Monday - Friday 9am - 5pm
              </p>
              <p>
                Email:
                <a href="mailto:office@gpplumbingandheating.com"
                  >office@gpplumbingandheating.com</a
                >
              </p>
            </div>
          </b-col>
          <b-col md='4'>
            <h3 class="footLBTitle">
              We're Social
            </h3>
            <div>
              <a
                href="https://www.facebook.com/GP-Plumbing-Heating-Engineers-Ltd-108684510837387/"
                target="_blank"
              >
                <font-awesome-icon
                  id="fab"
                  class="icon"
                  :icon="['fab', 'facebook']"
                />
              </a>
              <a href="https://twitter.com/HeatingGp?s=09" target="_blank">
                <font-awesome-icon
                  id="fab"
                  class="icon"
                  :icon="['fab', 'twitter']"
                />
              </a>
              <a
                href="https://instagram.com/gpplumbingltd?igshid=1o3o6srgvi8lu"
                target="_blank"
              >
                <font-awesome-icon
                  id="fab"
                  class="icon"
                  :icon="['fab', 'instagram']"
                />
              </a>
            </div>
            <div class="websiteLogo">
              Website by 
              <a href="https://ocs-sport.com/#/" target="_blank">
                <img class="gpLogo" src="https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/ocs-software-logo.png">
              </a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: "foot",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        pauseOnHover: true
      },
      sponsors: [],
        rcapt_sig_key: "YOUR KEY FROM GOOGLE",
        rcapt_id: 0 // will be used later
    };
  },
  mounted() {
    axios
      .get("https://gpplumbingandheating.com/wp-json/wp/v2/case-study")
      .then((response) => {
        this.sponsors = response.data;
      });
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
      document.head.appendChild(recaptchaScript)
  },
};
</script>

<style scoped>
::v-deep .slick-next:before {
  content: '';
  color: grey;
  height: 16px;
  width: 105px;
  background: url(https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/next.svg), no-repeat;
  background-size: 30px 30px;
  display: inline-block;
  width: 30px;
  height: 30px;
}
::v-deep .slick-prev:before {
  content: '';
  color: grey;
  height: 16px;
  width: 105px;
  background: url(https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/prev.svg), no-repeat;
  background-size: 30px 30px;
  display: inline-block;
  width: 30px;
  height: 30px;
}
img.sponsorsLogo.firstLogo {
  height: 192px;
}
::v-deep .slick-slide {
  padding: 15px;
}
img.sponsorsLogo {
  width: 100%;
}
.websiteLogo {
  position: absolute;
  bottom: -15px;
}
.gpLogo {
  width: 80px;
}
.g-recaptcha > ::v-deep div {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.g-recaptcha {
  position: relative;
}
.slideTitle {
  padding-top: 50px;
  margin-bottom: 2rem;
  color: #969696;
  text-align: left;
}
.sponRow {
  background-color: #f2f2f2;
  text-align: center;
  padding-bottom: 50px;
}
::v-deep .carousel-caption {
  right: 0;
  left: 0;
  width: 100%;
}
.c2a {
  padding: 0.5rem 2rem !important;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #00c196;
  border-color: #00c196;
}
.c2a:hover {
  background-color: #009b78;
  border-color: #008e6e;
}
.lightBlue {
  background-color: #0079c1;
  padding: 4rem 0;
  color: rgba(242, 242, 242, 0.5);
}
.footLBTitle {
  margin-bottom: 1.5rem;
  color: #ffffff;
}
.icon {
  width: 60px;
  font-size: 35px;
  color: rgba(242, 242, 242, 0.5) !important;
}
.para > p > a {
  color: rgba(242, 242, 242, 0.5) !important;
}
.gas {
  height: 70px;
  width: auto;
}
.bottomFoot{
  background-color: #00314a;
    height: 3rem;
}
@media only screen and (max-width: 425px) {
  ::v-deep .lightBlue>.container>.row>.col-md-4{
    padding-top: 20px;
  }
  img.workedCompany {
    min-height: 400px;
    display: block;
  }
  .carousel-item {
    height: 440px;
  }
}
@media only screen and (max-width: 990px) {
  .websiteLogo {
    position: relative;
    bottom: -15px;
  }
}
</style>
