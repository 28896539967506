<template>
  <div>
    <b-jumbotron
      v-if="
        this.$route.name === 'home' ||
          this.$route.name === 'about-us' ||
          this.$route.name === 'our-services'
      "
      class="jumbo tO"
      style="background-image: url(https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/gpplumbingandheating-jumbo.jpg);"
    >
      <b-container>
        <div>
          <h1
            v-if="this.$route.name === 'home'"
            class="overlayH"
            v-html="wpFields.jumbotron_text"
          ></h1>
          <h1
            v-if="
              this.$route.name === 'about-us' ||
                this.$route.name === 'our-services'
            "
            class="overlay"
            v-html="wpFields.jumbotron_text"
          ></h1>
        </div>

        <b-button
          v-if="this.$route.name === 'home'"
          :to="{
            name: 'about-us',
            query: {
              id: 11,
            },
          }"
          class="findBtn"
          href="#"
          >Find out about us</b-button
        >
      </b-container>
    </b-jumbotron>
    <b-jumbotron
      v-if="this.$route.name === 'case-studies'"
      class="jumbo tO"
      style="background-image: url('https://ocs-sport.ams3.digitaloceanspaces.com/gpplumbing/2020/03/GP-Plumbing.jpg');"
    >
      <b-container>
        <div>
          <h1 class="overlay" v-html="wpFields.jumbotron_text"></h1>
        </div>

        <b-button v-if="this.$route.name === 'home'" :to="{
                name: 'about-us',
                query: {
                  id: 11,
                },
              }" class="findBtn" href="#"
          >Find out about us</b-button
        >
      </b-container>
    </b-jumbotron>
    <b-jumbotron
      v-if="this.$route.name === 'contact-us'"
      class="jumbo contact"
      :style="'background-image: url(' + wpFields.jumbotron_image + ');'"
    >
      <div class="heading">
        <b-container>
          <b-row>
            <b-col lg="5">
              <div class="popOver">
                <span class="meta">{{ wpFields.jumbotron_heading }}</span>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <b-button
        v-if="this.$route.name === 'home'"
        class="findBtn"
        :to="{
          name: 'about-us',
          query: {
            id: 11,
          },
        }"
        href="#"
        >Find out about us</b-button
      >
    </b-jumbotron>
    <b-jumbotron
      v-if="this.$route.name === 'case'"
      class="jumbo tO"
      :style="'background-image: url(' + wpFields.thumbnail + ');'"
    >
      <b-container>
        <div>
          <h1 class="overlay" v-html="wpFields.client" v-if="wpFields.client.length > 0"></h1>
          <!-- <h1 class="overlay" v-html="wpFields.locations" v-if="wpFields.locations.length > 0"></h1> -->
          <br />
          <br />
          <h1 class="overlay light" v-html="wpFields.description" v-if="wpFields.description.length > 0"></h1>
        </div>

        <b-button
          v-if="this.$route.name === 'home'"
          :to="{
            name: 'about-us',
            query: {
              id: 11,
            },
          }"
          class="findBtn"
          href="#"
          >Find out about us</b-button
        >
      </b-container>
    </b-jumbotron>
    <b-jumbotron
      v-if="this.$route.name === 'careers'"
      class="jumbo tO"
      style="background-image: url('https://ocs-sport.ams3.digitaloceanspaces.com/gpplumbing/2020/03/GP-Plumbing.jpg');"
    >
      <b-container>
        <div>
          <h1 class="overlay" v-html="wpFields.jumbotron_text"></h1>
        </div>
      </b-container>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  name: "Jumbo",
  props: ["wpFields"],
};
</script>

<style scoped>
.jumbotron {
  border-radius: 0px!important;
}
.jumbo {
  background-position: center;
  background-size: cover;
}
.overlay {
  margin-bottom: 3rem;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  display: inline;
  padding: 0.25rem 1rem;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  line-height: 1.54;
}
.light{
  font-size: 2rem;
  font-weight: 400!important;
}
::v-deep .overlayH > p {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  display: inline;
  padding: 0.25rem 1rem;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  line-height: 1.54;
}
h1 {
  font-weight: 700 !important;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}
.findBtn {
  padding: 0.5rem 2rem !important;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #00c196;
  border-color: #00c196;
}
.findBtn:hover {
  background-color: #009b78;
  border-color: #008e6e;
}
.tO {
  padding: 8rem 0;
  margin-bottom: 0;
}
.heading {
  background: linear-gradient(to left, transparent 68%, #0079c1 32%);
}
.popOver {
  background-color: #0079c1;
  bottom: 0;
  padding: 2rem 2rem 2rem 0;
  position: relative;
  color: #ffffff;
  width: calc(100% - 50px);
  font-size: 1.5rem;
}
.popOver::before {
  content: "";
  width: 50px;
  height: calc(100% - 50px);
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -50px;
  border-bottom: auto solid #0079c1;
  border-right: 50px solid #0079c1;
}
.popOver::after {
  content: "";
  width: 50px;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -50px;
  border-bottom: 50px solid #0079c1;
  border-right: 50px solid transparent;
}
.contact {
  padding-top: 25rem;
  background-size: cover;
  background-position: center;
  padding-bottom: 0;
  margin-bottom: 0;
  padding-left: 0;
}
@media only screen and (max-width: 500px) {
  h1 {
    font-weight: 700 !important;
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }
  .tO {
    padding: 6rem 0;
    margin-bottom: 0;
  }
}
</style>
