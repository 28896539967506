<template>
  <div class="home">
    <div>
    <Jumbo :wpFields="wpFields.acf"/>
    </div>
    <div id='topSec'>
      <TopSec :wpFields="wpFields.acf" />
      <Services />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Jumbo from '@/components/jumbo.vue'
import TopSec from '@/components/topSec.vue'
import Services from '@/components/services.vue'
export default {
  name: "home",
  components:{Jumbo, TopSec, Services},
  data() {
    return {
      wpFields: [],
    };
  },
  mounted() {
    axios
      .get(
          "https://gpplumbingandheating.com/wp-json/wp/v2/pages/41"
      )
      .then((response) => {
        this.wpFields = response.data;
      });
  },
};
</script>

<style scoped>
#topSec{
  padding-top: 5rem;
}
</style>
