<template>
  <div>
    <b-container v-if="this.$route.name === 'home'">
      <b-row class="firstRow">
        <b-col md="6">
          <h3 v-html="wpFields.intro_title"></h3>
        </b-col>
        <b-col md="6">
          <span v-html="wpFields.intro_paragraph"></span>
        </b-col>
      </b-row>
      <b-row class="secRow">
        <b-col md="6" class="order-md-1 order-2">
          <div class="popOver">
            <span class="cite" v-html="wpFields.intro_cite"></span>
            <br />
            <span class="citeAuth" v-html="wpFields.intro_cite_author"></span>
          </div>
        </b-col>
        <b-col md="6" class="order-md-2 order-1">
          <b-button
            :to="{
              name: 'our-services',
              query: {
                id: 11,
              },
            }"
            class="c2a"
            >More about our services</b-button
          >
        </b-col>
      </b-row>
    </b-container>
    <b-container
      v-if="
        this.$route.name === 'about-us' || this.$route.name === 'our-services'
      "
      class="bPad"
    >
      <b-row>
        <b-col md="6">
          <div class="popOverWhite">
            <span class="cite" v-html="wpFields.intro_cite"></span>
            <br />
            <span class="citeAuth" v-html="wpFields.intro_cite_author"></span>
          </div>
          <div v-if="this.$route.name === 'about-us'">
            <span
              class="accred"
              v-html="wpFields.accreditation_paragraph"
            ></span>
            <b-img
              class="accredImg"
              :src="wpFields.accreditations_image"
            ></b-img>
            <b-button @click="scroll('accred')" class="c2a">See our accreditations</b-button
            ><span class="gap"></span
            ><b-button @click="scroll('award')" class="c2a">See our awards</b-button>
          </div>
        </b-col>
        <b-col md="6" class="secCol">
          <span class="secPara" v-html="wpFields.intro_paragraph"></span>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="this.$route.name === 'case'">
      <div class="topBlue">
        <b-container>
          <b-row>
            <b-col lg="10">
              <div class="popOverWhite" style="margin-bottom:0;">
                <b-row>
                  <b-col md="6">
                    <p class="TBPValue">
                      <span class="TBPTitle">Client: </span
                      >{{ wpFields.client }}
                    </p>
                    <p class="TBPValue">
                      <span class="TBPTitle">Requirements: </span>
                      {{ wpFields.requirements }}
                    </p>
                  </b-col>
                  <b-col md="6">
                    <p class="TBPValue">
                      <span class="TBPTitle">Location: </span
                      >{{ wpFields.locations }}
                    </p>
                    <p class="TBPValue">
                      <span class="TBPTitle">Date: </span>
                      {{ wpFields.date }}
                    </p>
                    <p class="TBPValue">
                      <span class="TBPTitle">Value: </span>
                      {{ wpFields.value }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <div v-if="this.$route.name === 'contact-us'" class="contactInfo">
      <b-container>
        <b-row>
          <b-col md="6">
            <span class="intro_1" v-html="wpFields.intro_paragraph"></span>
          </b-col>
          <b-col md="6">
            <span class="secPara" v-html="wpFields.about_paragraph"></span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div
              class="gp_intro_3 d-flex align-items-center justify-content-between ContactLogo"
            >
              <b-img
                class="marker"
                src="https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/marker.png"
              ></b-img>
              <span v-html="wpFields.statement"></span>
            </div>
          </b-col>
          <b-col>
            <div class="intro_4">
              <span class="address"
                >Unit 9A Ashdown Business Park<br />
                Michael Way<br />
                Maresfield<br />
                TN22 2DU</span
              >
              <span class="item"
                >Phone: <a href="tel:01732 865155">01732 865155</a></span
              >
              <span class="item"
                >Email:
                <a href="mailto:office@gpplumbingandheating.com"
                  >office@gpplumbingandheating.com</a
                ></span
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="this.$route.name === 'careers'" class="contactInfo">
      <b-container>
        <b-row>
          <b-col md="12">
            <span class="intro_1 contentcareers" v-html="wpFields.content"></span>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "topSec",
  props: ["wpFields"],
  methods: {
    scroll(value) {
      var discip = document.getElementById(value);
      discip.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
};
</script>

<style scoped>
span.intro_1.contentcareers {
  font-size: 1rem;
}
.jumbotron {
  border-radius: 0px!important;
}
.firstRow {
  align-items: center !important;
  margin-bottom: 2rem;
}
.popOver {
  z-index: 999;
  width: 100%;
  left: 0;
  background-color: #0079c1;
  padding: 3rem;
  position: relative;
  color: #ffffff;
  width: 100%;
  margin-bottom: -50px;
  font-style: italic;
}
.popOverWhite {
  z-index: 999;
  background-color: #0079c1;
  padding: 3rem;
  position: relative;
  color: #ffffff;
  width: 100%;
  font-style: italic;
  margin-bottom: 2rem;
}
.popOverWhite::after {
  content: "";
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 50px solid #0079c1;
  border-right: 50px solid #fff;
}
.popOver::after {
  content: "";
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 50px solid #0079c1;
  border-right: 50px solid #00314a;
}
.cite {
  font-size: 1.4rem;
}
.citeAuth {
  font-size: 1.1rem;
}
.c2a {
  padding: 0.5rem 2rem !important;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #00c196;
  border-color: #00c196;
}
.c2a:hover {
  background-color: #009b78;
  border-color: #008e6e;
}
.secCol {
  padding: 3rem 15px;
}
::v-deep .secPara > p {
  font-weight: 600;
}
.accred {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.accredImg {
  max-width: 70%;
  height: auto;
  margin-bottom: 4rem;
  margin-top: 4em;
}
.gap {
  padding: 15px;
}
.bPad {
  padding-bottom: 50px;
}
.topBlue {
  background: linear-gradient(to left, transparent 50%, #0079c1 50%);
}
.TBPTitle {
  color: rgba(242, 242, 242, 0.5);
}
.TBPValue {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 600;
}
.intro_1 {
  margin-bottom: 2rem;
  font-size: 1.4rem;
}
.contactInfo {
  padding: 4rem 0;
  background-color: #fff;
}
.gp_intro_3 {
  margin-bottom: 2rem;
  font-size: 1.3rem;
  color: #00c196;
}
.marker {
  margin-right: 1rem;
  height: 70px;
  width: auto;
}
.into_4 {
  margin-bottom: 2rem;
  color: #00314a;
}
.address {
  color: #00314a;
  margin-bottom: 2rem;
}
.item {
  display: block;
  margin-bottom: 0.5rem;
  color: #00c196;
}
.item > a {
  margin-bottom: 2rem;
  color: #00314a;
}
@media only screen and (max-width: 768px) {
  .c2a {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 425px) {
  .order-2 {
    padding-top: 40px;
  }
  .ContactLogo {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}
</style>
