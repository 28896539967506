var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-navbar',{staticClass:"topNav",attrs:{"variant":"faded","type":"light"}},[_c('b-container',[_c('span',[_c('font-awesome-icon',{staticClass:"icon",attrs:{"id":"fa","icon":['fa', 'clock']}}),_c('span',{staticClass:"mobHide"},[_vm._v(" Office hours ")]),_vm._v(" Monday - Friday 9am - 5pm")],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('div',{staticClass:"mobHide"},[_c('span',[_c('font-awesome-icon',{staticClass:"icon",attrs:{"id":"fa","icon":['fa', 'phone']}}),_c('a',{staticClass:"contact",attrs:{"href":"tel:01732865155"}},[_vm._v(" 01732 865155")])],1),_c('span',{staticStyle:{"padding-left":"10px"}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"id":"fa","icon":['fa', 'envelope']}}),_c('a',{staticClass:"contact",attrs:{"href":"mailto:office@gpplumbingandheating.com"}},[_vm._v(" office@gpplumbingandheating.com")])],1)]),_c('div',{staticClass:"mobShow"},[_c('b-nav-item',{staticClass:"mobContact",class:{ current: this.$route.name === 'contact-us' },attrs:{"to":{
              name: 'contact-us',
              query: {
                id: 17,
              },
            }}},[_vm._v("Contact Us")])],1)])],1)],1),_c('b-navbar',{staticClass:"secondNav",attrs:{"toggleable":"xl","type":"dark"}},[_c('b-container',[_c('b-navbar-brand',{attrs:{"to":"/"}},[_c('b-img',{staticClass:"logo mobHide",attrs:{"src":"https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/gpplumbing-logo.png"}}),_c('b-img',{staticClass:"logo mobShow",attrs:{"src":"https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/gpplumbing-logo-sm.png"}})],1),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"},scopedSlots:_vm._u([{key:"default",fn:function({ expanded }){return [(expanded)?_c('font-awesome-icon',{staticClass:"menuClose",attrs:{"icon":['fa', 'times']}}):_c('font-awesome-icon',{staticClass:"menuBurger",attrs:{"icon":['fa', 'bars']}})]}}])}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{staticClass:"menuLink",class:{ current: this.$route.name === 'home' },attrs:{"to":"/"}},[_vm._v("Home")]),_c('b-nav-item',{staticClass:"menuLink",class:{ current: this.$route.name === 'about-us' },attrs:{"to":{
              name: 'about-us',
              query: {
                id: 11,
              },
            }}},[_vm._v("About Us")]),_c('b-nav-item',{staticClass:"menuLink",class:{ current: this.$route.name === 'our-services' },attrs:{"to":{
              name: 'our-services',
              query: {
                id: 13,
              },
            }}},[_vm._v("Our Services")]),_c('b-nav-item',{staticClass:"menuLink",class:{ current: this.$route.name === 'case-studies' },attrs:{"to":{
              name: 'case-studies',
              query: {
                id: 15,
              },
            }}},[_vm._v("Projects")]),_c('b-nav-item',{staticClass:"menuLink",class:{ current: this.$route.name === 'contact-us' },attrs:{"to":{
              name: 'contact-us',
              query: {
                id: 17,
              },
            }}},[_vm._v("Contact Us")]),_c('b-nav-item',{staticClass:"menuLink",class:{ current: this.$route.name === 'careers' },attrs:{"to":{
              name: 'careers',
              query: {
                id: 546,
              },
            }}},[_vm._v("Careers")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }