var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      this.$route.name === 'home' ||
        this.$route.name === 'about-us' ||
        this.$route.name === 'our-services'
    )?_c('b-jumbotron',{staticClass:"jumbo tO",staticStyle:{"background-image":"url(https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/gpplumbingandheating-jumbo.jpg)"}},[_c('b-container',[_c('div',[(this.$route.name === 'home')?_c('h1',{staticClass:"overlayH",domProps:{"innerHTML":_vm._s(_vm.wpFields.jumbotron_text)}}):_vm._e(),(
            this.$route.name === 'about-us' ||
              this.$route.name === 'our-services'
          )?_c('h1',{staticClass:"overlay",domProps:{"innerHTML":_vm._s(_vm.wpFields.jumbotron_text)}}):_vm._e()]),(this.$route.name === 'home')?_c('b-button',{staticClass:"findBtn",attrs:{"to":{
          name: 'about-us',
          query: {
            id: 11,
          },
        },"href":"#"}},[_vm._v("Find out about us")]):_vm._e()],1)],1):_vm._e(),(this.$route.name === 'case-studies')?_c('b-jumbotron',{staticClass:"jumbo tO",staticStyle:{"background-image":"url('https://ocs-sport.ams3.digitaloceanspaces.com/gpplumbing/2020/03/GP-Plumbing.jpg')"}},[_c('b-container',[_c('div',[_c('h1',{staticClass:"overlay",domProps:{"innerHTML":_vm._s(_vm.wpFields.jumbotron_text)}})]),(this.$route.name === 'home')?_c('b-button',{staticClass:"findBtn",attrs:{"to":{
              name: 'about-us',
              query: {
                id: 11,
              },
            },"href":"#"}},[_vm._v("Find out about us")]):_vm._e()],1)],1):_vm._e(),(this.$route.name === 'contact-us')?_c('b-jumbotron',{staticClass:"jumbo contact",style:('background-image: url(' + _vm.wpFields.jumbotron_image + ');')},[_c('div',{staticClass:"heading"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"lg":"5"}},[_c('div',{staticClass:"popOver"},[_c('span',{staticClass:"meta"},[_vm._v(_vm._s(_vm.wpFields.jumbotron_heading))])])])],1)],1)],1),(this.$route.name === 'home')?_c('b-button',{staticClass:"findBtn",attrs:{"to":{
        name: 'about-us',
        query: {
          id: 11,
        },
      },"href":"#"}},[_vm._v("Find out about us")]):_vm._e()],1):_vm._e(),(this.$route.name === 'case')?_c('b-jumbotron',{staticClass:"jumbo tO",style:('background-image: url(' + _vm.wpFields.thumbnail + ');')},[_c('b-container',[_c('div',[(_vm.wpFields.client.length > 0)?_c('h1',{staticClass:"overlay",domProps:{"innerHTML":_vm._s(_vm.wpFields.client)}}):_vm._e(),_c('br'),_c('br'),(_vm.wpFields.description.length > 0)?_c('h1',{staticClass:"overlay light",domProps:{"innerHTML":_vm._s(_vm.wpFields.description)}}):_vm._e()]),(this.$route.name === 'home')?_c('b-button',{staticClass:"findBtn",attrs:{"to":{
          name: 'about-us',
          query: {
            id: 11,
          },
        },"href":"#"}},[_vm._v("Find out about us")]):_vm._e()],1)],1):_vm._e(),(this.$route.name === 'careers')?_c('b-jumbotron',{staticClass:"jumbo tO",staticStyle:{"background-image":"url('https://ocs-sport.ams3.digitaloceanspaces.com/gpplumbing/2020/03/GP-Plumbing.jpg')"}},[_c('b-container',[_c('div',[_c('h1',{staticClass:"overlay",domProps:{"innerHTML":_vm._s(_vm.wpFields.jumbotron_text)}})])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }