<template>
  <div>
    <b-navbar variant="faded" type="light" class="topNav">
      <b-container>
        <span
          ><font-awesome-icon id="fa" class="icon" :icon="['fa', 'clock']" />
          <span class='mobHide'> Office hours </span> Monday - Friday 9am - 5pm</span
        >
        <b-navbar-nav class="ml-auto">
          <div class="mobHide">
            <span
              ><font-awesome-icon
                id="fa"
                class="icon"
                :icon="['fa', 'phone']"
              />
              <a class="contact" href="tel:01732865155"> 01732 865155</a></span
            >
            <span style="padding-left:10px;"
              ><font-awesome-icon
                id="fa"
                class="icon"
                :icon="['fa', 'envelope']"
              /><a
                class="contact"
                href="mailto:office@gpplumbingandheating.com"
              >
                office@gpplumbingandheating.com</a
              ></span
            >
          </div>
          <div class="mobShow">
            <b-nav-item
              class='mobContact'
              :class="{ current: this.$route.name === 'contact-us' }"
              :to="{
                name: 'contact-us',
                query: {
                  id: 17,
                },
              }"
              >Contact Us</b-nav-item
            >
          </div>
        </b-navbar-nav>
      </b-container>
    </b-navbar>

    <b-navbar toggleable="xl" type="dark" class="secondNav">
      <b-container>
        <b-navbar-brand to="/"
          ><b-img
            class="logo mobHide"
            src="https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/gpplumbing-logo.png"
          ></b-img>
          <b-img
            class="logo mobShow"
            src="https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/gpplumbing-logo-sm.png"
          ></b-img>
          
          </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse">
          <template #default="{ expanded }">
            <font-awesome-icon
              v-if="expanded"
              :icon="['fa', 'times']"
              class="menuClose"
            ></font-awesome-icon>
            <font-awesome-icon
              v-else
              :icon="['fa', 'bars']"
              class="menuBurger"
            ></font-awesome-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              class="menuLink"
              :class="{ current: this.$route.name === 'home' }"
              to="/"
              >Home</b-nav-item
            >
            <b-nav-item
              class="menuLink"
              :class="{ current: this.$route.name === 'about-us' }"
              :to="{
                name: 'about-us',
                query: {
                  id: 11,
                },
              }"
              >About Us</b-nav-item
            >
            <b-nav-item
              class="menuLink"
              :class="{ current: this.$route.name === 'our-services' }"
              :to="{
                name: 'our-services',
                query: {
                  id: 13,
                },
              }"
              >Our Services</b-nav-item
            >
            <b-nav-item
              class="menuLink"
              :class="{ current: this.$route.name === 'case-studies' }"
              :to="{
                name: 'case-studies',
                query: {
                  id: 15,
                },
              }"
              >Projects</b-nav-item
            >
            <b-nav-item
              class="menuLink"
              :class="{ current: this.$route.name === 'contact-us' }"
              :to="{
                name: 'contact-us',
                query: {
                  id: 17,
                },
              }"
              >Contact Us</b-nav-item
            >
            <b-nav-item
              class="menuLink"
              :class="{ current: this.$route.name === 'careers' }"
              :to="{
                name: 'careers',
                query: {
                  id: 546,
                },
              }"
              >Careers</b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "menu",
};
</script>

<style scoped>
.topNav {
  background-color: #0079c1;
  color: #fff;
}
.logo {
  height: 70px;
  width: auto;
}
.secondNav {
  background-color: #ffffff;
  padding: 1.5rem 0 1rem;
  color: #000000;
}
.icon {
  color: rgba(242, 242, 242, 0.5);
}

.menuLink > a {
  color: #000000 !important;
  font-size: 1.2rem;
}
.menuLink > a:hover {
  color: #00c196 !important;
}
.contact {
  color: #fff !important;
}
.current > a {
  color: #00c196 !important;
}
.menuBurger {
  border: none;
  padding: 0;
  font-size: 2.5rem;
  color: #0079c1;
}
.menuClose {
  border: none;
  padding: 0;
  font-size: 2rem;
  color: #00c196;
}
.mobShow {
  display: none;
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
  .mobShow {
    display: block;
  }
  .mobContact>a{
    color: #fff!important;
  }
  .menuLink > a {
    color: #000000 !important;
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 768px) {
  .secondNav{
    padding: 1.5rem 1rem 1rem;
  }
  li.nav-item.menuLink {
    border-bottom: 1px solid #d3d3d3;
  }
  li.nav-item.menuLink:first-child {
    border-top: 1px solid #d3d3d3;
    margin-top: 1em;
  }
  li.nav-item.menuLink:last-child {
    border-bottom: 0px solid #d3d3d3;
  }
  .logo {
    height: 50px;
    width: auto;
  }
}

</style>
