<template>
  <div :class="{home : this.$route.name === 'home', services: this.$route.name === 'our-services'}">
    <b-container>
      <b-row>
        <b-col
          lg="4"
          md='6'
          cols='12'
          v-for="(serv, index) in services.slice().reverse()"
          :key="index"
        >
              <b-img class="serveLogo" :src="serv.acf.thumbnail"></b-img>
              <h3 class='servTitle' v-html="serv.title.rendered">
              </h3>
              <p class='servDes'>{{serv.acf.description}}</p>
            </b-col>
          </b-row>
          <b-row v-if="this.$route.name === 'home'">
              <b-col class='servBtnCol'>
                  <b-button :to="{
                name: 'our-services',
                query: {
                  id: 13,
                },
              }" class='c2a'>Require our services? Get in touch today</b-button>
              </b-col>
          </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "services",
  data() {
    return {
      services: [],
    };
  },
  mounted() {
    axios
      .get("https://gpplumbingandheating.com/wp-json/wp/v2/service")
      .then((response) => {
        this.services = response.data;
      });
  },
};
</script>

<style scoped>
.home{
    padding: 9rem 0 5rem 0;
  background-color: #00314a;
  color: #ffffff;
}
.services {
  padding: 0rem 0 5rem 0;
  background-color: #fff;
  color: #00314a!important;
}
.serveLogo {
  width: 70px;
  margin-bottom: 1rem;
}
.servTitle{
        margin-bottom: 1.5rem;
}
.servDes{
    color: #969696;
}
.servBtnCol{
    text-align: center;
}
.c2a{
      padding: 0.5rem 2rem !important;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #00c196;
  border-color: #00c196;
}
.c2a:hover{
        background-color: #009b78;
    border-color: #008e6e;
}
@media only screen and (max-width: 768px) {
  .home{
padding: 6.5rem 0 5rem 0;
  }
  .services{
    padding: .5rem 0 5rem 0;
  }
}
</style>
